<template>
  <ul class="fahrplan_results">
    <li
      v-for="result in results"
      :key="result.advancedId"
      @click="$emit('selected', result)"
    >
      <img v-if="result.type === 'ADDRESS'"  :src="'/resources/imgs/adresse.gif'" alt="ADR ">
      <img v-if="result.type === 'STATION'"  :src="'/resources/imgs/hst.gif'" alt="HST ">
      <span>{{ result.displayName }}</span> 
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue";
import type { fahrplanResponseEntry } from "../types/fahrplan";

export default defineComponent({
  props: {
    results: {
      type: Array as PropType<fahrplanResponseEntry[]>,
      required: true
    }
  },
  setup() {
    return {};
  },
});
</script>

<style scoped>
</style>