<template>
  <link rel="stylesheet" :href="`${baseURL}/resources/css/bundled.styles.css`">
  <div id="fahrplansuche" class="fahrplansuche">
    <div class="uk-flex input_wrapper">
      <div class="start__wrapper">
        <input
          @input="debouncedSearch(true)"
          class="uk-input"
          placeholder="von"
          type="search"
          v-model="von"
        />
        <ResultDisplay v-if="displayToShow == 'von' && vonResults.length && !hideDisplay" :results="vonResults" @selected="(station) => setVonStation(station)" />
      </div>
      <div class="end_wrapper">
        <input
          @input="debouncedSearch(false)"
          class="uk-input"
          placeholder="nach"
          type="search"
          v-model="nach"
        />
        <ResultDisplay v-if="displayToShow == 'nach' && nachResults.length && !hideDisplay" :results="nachResults" @selected="(station) => setNachStation(station)" />
      </div>
    </div>
    <button class="uk-button-primary uk-button"><a :href="`${baseURL}/fahrplan/fahrplanauskunft${bookingURL}`">Suchen</a></button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { ofetch } from "ofetch";
import { debounce } from "perfect-debounce";

import ResultDisplay from "./resultDisplay.vue";

import type {fahrplanResponseEntry} from "../types/fahrplan";

export default defineComponent({
  components: {
    ResultDisplay
  },
  setup() {
    const baseUrlApi = 'https://muv.tdimo.net';
    const locationServiceUrl = `${baseUrlApi}/muv/AuskunftLocationAjax.action`;
    const commonParams = {
      apiVersion: 1,
      specNumber: 1,
      clientName: 'banana',
      clientVersion: '1.0' // oder Ihre interne Versionsnummer
    };
    const baseURL = computed(() => {
      const url = new URL(location.href)
      return url.origin
    })

    const displayToShow = ref<"von" | "nach">("von")

    const von = ref<string>("");
    const nach = ref<string>("");

    const vonResults = ref<fahrplanResponseEntry[]>([]);
    const nachResults = ref<fahrplanResponseEntry[]>([]);

    const selectedVon = ref<fahrplanResponseEntry | null>(null);
    const selectedNach = ref<fahrplanResponseEntry | null>(null);

    const hideDisplay = ref(false);


    const debouncedSearch = debounce(async (input: boolean = true) => {
      if(input){
        displayToShow.value = "von";
      } else {
        displayToShow.value = "nach";
      }
      console.log("2");
      hideDisplay.value = false;
      getLocationData(input);
    }, 250)

    async function getLocationData(isStart: boolean) {
      const params = {
        ...commonParams,
        serviceData: {
          stoppointName: isStart ? von.value : nach.value,
          cityId: '',
          startSuche: isStart,
          auskunftType: 1
        }
      };

      try {
        const response = await ofetch<fahrplanResponseEntry[]>(locationServiceUrl, {
          "method": "get",
          "parseResponse": JSON.parse,
          "params": params
         });

         if (isStart) {
          vonResults.value = response
         } else {
          nachResults.value = response
         }
      } catch (error) {
        console.error('Error fetching location data:', error);
        throw error;
      }
    }

    function setVonStation(station: fahrplanResponseEntry) {
      hideDisplay.value = true
      selectedVon.value = station;
      von.value = station.displayName
    }

    function setNachStation(station: fahrplanResponseEntry) {
      hideDisplay.value = true
      selectedNach.value = station;
      nach.value = station.displayName
    }

    const bookingURL = computed<string>(() => {
      console.log(`${baseUrlApi}/bapp/#/autofill?from=${encodeURIComponent(JSON.stringify(selectedVon.value))}&to=${encodeURIComponent(JSON.stringify(selectedNach.value))}`);
      const bookingUrl = `?from=${encodeURIComponent(JSON.stringify(selectedVon.value))}&to=${encodeURIComponent(JSON.stringify(selectedNach.value))}`;
      return bookingUrl;
    })

    return {
      baseURL,
      debouncedSearch,
      von,
      nach,
      setVonStation,
      setNachStation,
      bookingURL,
      vonResults,
      nachResults,
      displayToShow,
      selectedVon,
      selectedNach,
      hideDisplay
    };
  },
});
</script>


<style>

</style>